var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "transport-bill-track" },
    [
      _c("trailBack", { ref: "trailMap" }),
      _c("div", { staticClass: "bottom-cont" }, [
        _c(
          "div",
          { staticClass: "button-close", on: { click: _vm.handleClose } },
          [
            _c("Icon", {
              staticStyle: { "margin-right": "8px" },
              attrs: { type: "ios-arrow-" + (_vm.isClosed ? "up" : "down") }
            }),
            _vm._v(_vm._s((_vm.isClosed ? "打开" : "关闭") + "运单明细") + " ")
          ],
          1
        ),
        !_vm.isClosed
          ? _c(
              "div",
              { staticClass: "bill-info" },
              [
                _c(
                  "Row",
                  [
                    _c("Col", { attrs: { span: 4 } }, [
                      _vm._v("运单号：" + _vm._s(_vm.shippingNoteNumber))
                    ]),
                    _c("Col", { attrs: { span: 18 } }, [
                      _vm._v(
                        "轨迹起始时间：" +
                          _vm._s(_vm.startTime) +
                          " ~ " +
                          _vm._s(_vm.endTime)
                      )
                    ]),
                    _c(
                      "Col",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end"
                        },
                        attrs: { span: 2 }
                      },
                      [
                        _c(
                          "Button",
                          {
                            attrs: { size: "default" },
                            on: { click: _vm.handleRefresh }
                          },
                          [_vm._v("刷新")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._l(_vm.routeInfo, function(item, idx) {
                  return _c(
                    "div",
                    { key: idx },
                    [
                      _c(
                        "Row",
                        [
                          _c("Col", { attrs: { span: 4 } }, [
                            _vm._v("线路信息：" + _vm._s(_vm.route))
                          ]),
                          _c("Col", { attrs: { span: 9 } }, [
                            _c("div", { staticClass: "align-center" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/zhuanghuo.svg"
                                }
                              }),
                              _vm._v(_vm._s(item.load.address) + " ")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "align-center",
                                staticStyle: { "margin-top": "8px" }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/dianziweilan.svg"
                                  }
                                }),
                                _vm._v(
                                  "围栏半径：" +
                                    _vm._s(
                                      _vm.filterKm(item.load.triggerScope)
                                    ) +
                                    "km "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "align-center",
                                staticStyle: { "margin-top": "8px" }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "16px",
                                    margin: "0 10px 0 2px"
                                  },
                                  attrs: {
                                    src:
                                      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/PC/shijianjiedian.svg"
                                  }
                                }),
                                _vm._v(
                                  "装货触发时间：" +
                                    _vm._s(item.load.triggerDelay / 60) +
                                    "min "
                                )
                              ]
                            )
                          ]),
                          _c("Col", { attrs: { span: 9 } }, [
                            _c("div", { staticClass: "align-center" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/xiehuo.svg"
                                }
                              }),
                              _vm._v(_vm._s(item.unload.address) + " ")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "align-center",
                                staticStyle: { "margin-top": "8px" }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/dianziweilan.svg"
                                  }
                                }),
                                _vm._v(
                                  "围栏半径：" +
                                    _vm._s(
                                      _vm.filterKm(item.unload.triggerScope)
                                    ) +
                                    "km "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "align-center",
                                staticStyle: { "margin-top": "8px" }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "16px",
                                    margin: "0 10px 0 2px"
                                  },
                                  attrs: {
                                    src:
                                      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/PC/shijianjiedian.svg"
                                  }
                                }),
                                _vm._v(
                                  "卸货触发时间：" +
                                    _vm._s(item.unload.triggerDelay / 60) +
                                    "min "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }