<template>
  <div class="transport-bill-track">
    <trailBack ref="trailMap"></trailBack>
    <div class="bottom-cont">
      <div class="button-close" @click="handleClose">
        <Icon
          :type="`ios-arrow-${isClosed ? 'up' : 'down'}`"
          style="margin-right: 8px;"
        />{{ `${isClosed ? '打开' : '关闭'}运单明细` }}
      </div>
      <div v-if="!isClosed" class="bill-info">
        <Row>
          <Col :span="4">运单号：{{ shippingNoteNumber }}</Col>
          <Col :span="18">轨迹起始时间：{{ startTime }} ~ {{ endTime }}</Col>
          <Col :span="2" style="display:flex;justify-content:flex-end;"
            ><Button size="default" @click="handleRefresh">刷新</Button></Col
          >
        </Row>
        <div v-for="(item, idx) in routeInfo" :key="idx">
          <Row>
            <Col :span="4"
              >线路信息：{{route}}</Col
            >
            <Col :span="9"
              ><div class="align-center">
                <img
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/zhuanghuo.svg"
                />{{ item.load.address }}
              </div>
              <div style="margin-top:8px;" class="align-center">
                <img
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/dianziweilan.svg"
                />围栏半径：{{ filterKm(item.load.triggerScope) }}km
              </div><div style="margin-top:8px;" class="align-center">
                <img style="width:16px;margin:0 10px 0 2px;"
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/PC/shijianjiedian.svg"
                />装货触发时间：{{ item.load.triggerDelay / 60 }}min
              </div></Col
            >
            <Col :span="9"
              ><div class="align-center">
                <img
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/xiehuo.svg"
                />{{ item.unload.address }}
              </div>
              <div style="margin-top:8px;" class="align-center">
                <img
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/dianziweilan.svg"
                />围栏半径：{{ filterKm(item.unload.triggerScope) }}km
              </div><div style="margin-top:8px;" class="align-center">
                <img style="width:16px;margin:0 10px 0 2px;"
                  src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/PC/shijianjiedian.svg"
                />卸货触发时间：{{ item.unload.triggerDelay / 60 }}min
              </div></Col
            >
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ajax } from '../../libs/util'
import trailBack from '../devices/trail-back.vue'
import moment from 'moment'
import BigNumber from 'bignumber.js'
export default {
  components: { trailBack },
  data() {
    return {
      shippingNoteNumber: '',
      startTime: '',
      endTime: '',
      routeInfo: [],
      route:'',
      shipId: '',
      isClosed: false,
    }
  },
  methods: {
    handleRefresh() {
      this.endTime = ''
      this.requestData()
    },
    handleBack() {
      this.$router.push({ path: '/monitor/list' })
    },
    requestData() {
      ajax.post(
        '/shipping/shipping_note_list',
        { shippingNoteNumber: this.shippingNoteNumber },
        (res) => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data[0] &&
            res.data.data[0].fenceInfoList[0]
          ) {
            this.startTime = res.data.data[0].fenceInfoList[0].triggerTimeStr

            let loadInfo = null
            this.routeInfo = []
            this.route = ''
            let isTriggered = false
            res.data.data[0].fenceInfoList.forEach((item) => {
              if(item.type === 3 && item.triggerStatus == 1) {
                isTriggered = true
              }
            })
            res.data.data[0].fenceInfoList.forEach((item) => {
              // if (item.type === 1) {
              //   loadInfo = item
              // } else if (item.type === 3 && item.triggerStatus != 2) {
              //   this.routeInfo.push({
              //     load: loadInfo,
              //     unload: item,
              //   })

              //   if (item.triggerStatus === 1) {
              //     this.endTime = item.triggerTimeStr
              //   }
              // }
              if(isTriggered) {
                if (item.type === 3 && item.triggerStatus === 1) {//如果是卸货围栏
                  this.route = item.route
                }
              }else {
                if (item.type === 3 && item.triggerStatus !== 2) {//如果是卸货围栏
                  this.route = item.route
                }
              }
              
              if (item.type === 1) {//装货
                loadInfo = item
              } else if (item.type === 3) {//卸货
                if(isTriggered) {
                  if(item.triggerStatus === 1) {
                    this.routeInfo.push({
                      load: loadInfo,
                      unload: item,
                    })
                  }
                }else {
                  if(item.triggerStatus !== 2) {
                    this.routeInfo.push({
                      load: loadInfo,
                      unload: item,
                    })
                  }
                }

                if (item.triggerStatus === 1) {
                  this.endTime = item.triggerTimeStr
                }
              }
            })
            if (!this.endTime) {
              this.endTime = moment().format('YYYY-MM-DD HH:mm:ss')
            }
            this.$refs.trailMap.setDeviceAndSearch(res.data.data[0])
          }
        },
        (err) => {
          this.$Message.error(err.message || err.msg || '服务器异常，请重试')
        },
      )
    },
    handleClose() {
      this.isClosed = !this.isClosed
    },
    filterKm(meter) {
      return new BigNumber(meter)
        .dividedBy(1000)
        .decimalPlaces(3, BigNumber.ROUND_HALF_UP)
        .toString()
    },
  },
  created() {
    this.shippingNoteNumber = this.$route.query.shippingNoteNumber || ''
    this.requestData()
  },
}
</script>

<style lang="less">
.transport-bill-track {
  color: #333;
  line-height: 20px;
  .bottom-cont {
    position: absolute;
    bottom: 0;
    width: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    .button-close {
      border: 1px solid #dbdee3;
      border-bottom: none;
      cursor: pointer;
      width: 124px;
      border-radius: 8px 8px 0 0;
      padding: 5px 8px;
      background-color: white;
    }
    .bill-info {
      height: 198px;
      width: 100%;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
      background-color: white;
      z-index: 9999;
      padding: 24px 32px;
      .pt-30 {
        padding-top: 30px;
      }
      .route-info {
        display: flex;
        align-items: center;
        padding-top: 16px;
        .pl-24 {
          padding-left: 24px;
        }
      }
      img {
        width: 21px;
        margin-right: 8px;
      }
      .align-center {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
